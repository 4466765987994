import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import FormComponent from "../components/ui/FormComponent";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";

export default function IndexPage({ data, location }) {
  console.log(data.websiteprice);
  return (
    <Layout
      location={location.pathname}
      title="Оставить заявку"
      description="Оставьте заявку и мы с вами свяжемся"
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Заказать сайт</TitleComponent>
        <FormComponent />
      </MarginComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    projects: allSanityProjects(
      limit: 4
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        name
        description
        id
        slug {
          current
        }
        image {
          asset {
            id
            gatsbyImageData(width: 700)
          }
        }
      }
    }
  }
`;
